import * as React from "react";
import { FunctionComponent } from "react";
import {
  Button,
  Heading,
  Input,
  Link,
  Page,
  PageContent,
  PageSection,
  Paragraph,
  Tile
} from "../components";
import { useStaticQuery, graphql } from "gatsby";
import Carousel from "nuka-carousel";

import documentIconSrc from "../images/icon-document.png";
import doubleDownArrowIconSrc from "../images/icon-double-arrow-down.png";
import externalLinkIcon from "../images/property-website-link-icon.svg";
import informationIconSrc from "../images/icon-information.png";
import financialIconSrc from "../images/icon-financial.png";
import logoSrc from "../images/logo_white.png";
import magnifyingGlassIconSrc from "../images/icon-magnifying-glass.png";
import questionMarkIconSrc from "../images/icon-magnifying-glass.png";

import "./Home.css";

const Index: FunctionComponent = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulGeneralEditableField {
        edges {
          node {
            title
            pageSection
            childContentfulGeneralEditableFieldContentRichTextNode {
              childContentfulRichText {
                html
              }
            }
          }
        }
      }
      allContentfulHomePageHeroCarousel(
        sort: { fields: updatedAt, order: DESC }
      ) {
        edges {
          node {
            backgroundImage {
              file {
                url
                fileName
                contentType
              }
            }
          }
        }
      }
      iAmAnOwnerImage: contentfulAsset(title: { eq: "Home > I am an Owner" }) {
        ...contentfulAssetFields
      }
      iAmARealtorImage: contentfulAsset(
        title: { eq: "Home > I am a Realtor" }
      ) {
        ...contentfulAssetFields
      }
      learnMoreAboutHawaiianaImage: contentfulAsset(
        title: { eq: "Home > I want to learn more about Hawaiiana" }
      ) {
        ...contentfulAssetFields
      }
      findInformationAboutYourCommunityImage: contentfulAsset(
        title: { eq: "Home > Find information about your community" }
      ) {
        ...contentfulAssetFields
      }
      aboutHawaiianaImage: contentfulAsset(
        title: { eq: "Home > About Hawaiiana" }
      ) {
        ...contentfulAssetFields
      }
    }

    fragment contentfulAssetFields on ContentfulAsset {
      description
      file {
        url
      }
    }
  `);
  const aboutHawaiianaSection = data.allContentfulGeneralEditableField.edges.filter(
    field => field.node.pageSection === "homeAboutHawaiiana"
  );

  const paymentEmailArray = data.allContentfulGeneralEditableField.edges.filter(
    field => field.node.pageSection === "paymentEmail"
  );
  const paymentEmail = paymentEmailArray.length
    ? paymentEmailArray[0].node.childContentfulGeneralEditableFieldContentRichTextNode.childContentfulRichText.html.replace(
        /<\/?[^>]+(>|$)/g,
        ""
      )
    : null;

  const paymentPhoneNumberArray = data.allContentfulGeneralEditableField.edges.filter(
    field => field.node.pageSection === "paymentPhoneNumber"
  );
  const paymentPhoneNumber = paymentPhoneNumberArray.length
    ? paymentPhoneNumberArray[0].node.childContentfulGeneralEditableFieldContentRichTextNode.childContentfulRichText.html.replace(
        /<\/?[^>]+(>|$)/g,
        ""
      )
    : null;

  const inquiryEmailArray = data.allContentfulGeneralEditableField.edges.filter(
    field => field.node.pageSection === "inquiryEmail"
  );
  const inquiryEmail = inquiryEmailArray.length
    ? inquiryEmailArray[0].node.childContentfulGeneralEditableFieldContentRichTextNode.childContentfulRichText.html.replace(
        /<\/?[^>]+(>|$)/g,
        ""
      )
    : null;

  const inquiryPhoneNumberArray = data.allContentfulGeneralEditableField.edges.filter(
    field => field.node.pageSection === "inquiryPhoneNumber"
  );
  const inquiryPhoneNumber = inquiryPhoneNumberArray.length
    ? inquiryPhoneNumberArray[0].node.childContentfulGeneralEditableFieldContentRichTextNode.childContentfulRichText.html.replace(
        /<\/?[^>]+(>|$)/g,
        ""
      )
    : null;

  const carouselImages = data.allContentfulHomePageHeroCarousel.edges;
  const {
    iAmAnOwnerImage,
    iAmARealtorImage,
    learnMoreAboutHawaiianaImage,
    findInformationAboutYourCommunityImage,
    aboutHawaiianaImage
  } = data;
  const autoplay = carouselImages.length > 1;
  return (
    <Page title="Hawaiiana Management Company" layout="default">
      <div className="home-gradient-section">
        <Carousel
          wrapAround={autoplay}
          renderCenterLeftControls={() => null}
          renderCenterRightControls={() => null}
          autoplayInterval={3000}
          autoplay={autoplay}
        >
          {carouselImages.map((carouselImage, carouselImageIndex) => {
            if (
              carouselImage.node.backgroundImage.file.contentType ===
              "video/mp4"
            ) {
              return (
                <div
                  className="home-hero"
                  key={`carousel-hero-${carouselImageIndex}`}
                >
                  <Tile
                    style={{
                      margin: "auto",
                      position: "relative"
                    }}
                  >
                    <video
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0
                      }}
                      autoPlay
                      loop
                      muted
                    >
                      <source
                        src={carouselImage.node.backgroundImage.file.url}
                      />
                    </video>
                  </Tile>
                </div>
              );
            } else {
              return (
                <div
                  className="home-hero"
                  key={`carousel-hero-${carouselImageIndex}`}
                >
                  <Tile
                    style={{
                      margin: "auto",
                      background: `url(${
                        carouselImage.node.backgroundImage.file.url
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center"
                    }}
                  />
                </div>
              );
            }
          })}
        </Carousel>

        <div className="flex-wrapper home-help">
          <div className="contact-split">
            <Heading level={3}>Aloha</Heading>
            <Heading level={4}>How can we help you?</Heading>

            <Link href="#tools-section" className="link-light-with-icon">
              <img src={doubleDownArrowIconSrc} />
              <span>Find the tools you need</span>
            </Link>
          </div>
          <div className="contact-split">
            <img
              src={logoSrc}
              className="logo-img"
              alt="Hawaiiana Management Company homepage"
            />
          </div>
        </div>
        <div
          className="home-easy-tools flex-wrapper home-section"
          id="tools-section"
        >
          <div className="contact-split">
            <div className="tile">
              <Paragraph>{`I'm an`}</Paragraph>
              <Heading level={3} style={{ marginTop: 0 }}>
                Owner
              </Heading>
              <img
                className="tool-image"
                src={iAmAnOwnerImage.file.url}
                alt={iAmAnOwnerImage.description}
              />
              <Link href="/find-a-community" className="link-with-icon">
                <Paragraph>
                  <img src={magnifyingGlassIconSrc} alt="Community look up" />
                  <span>Look up community information</span>
                </Paragraph>
              </Link>
              <Link href="/contact-us" className="link-with-icon">
                <Paragraph>
                  <img
                    src={questionMarkIconSrc}
                    alt="Contact accounting specialist"
                  />
                  <span>
                    Contact an Accounting Specialist with questions about your
                    account
                  </span>
                </Paragraph>
              </Link>
              <Link href="/change-address" className="link-with-icon">
                <Paragraph>
                  <img src={documentIconSrc} alt="Change your address" />
                  <span>Change your address</span>
                </Paragraph>
              </Link>
              <Link href="/make-a-payment" className="link-with-icon">
                <Paragraph>
                  <img src={financialIconSrc} alt="Pay online" />
                  <span>Pay online</span>
                </Paragraph>
              </Link>
            </div>
          </div>
          <div className="contact-split home-column">
            <div className="tile">
              <div className="flex-wrapper">
                <div className="contact-split">
                  <Paragraph>{`I'm a`}</Paragraph>
                  <Heading level={3} style={{ marginTop: 0 }}>
                    Realtor
                  </Heading>
                  <Link
                    href="https://www.docutrieve.com/"
                    className="link-with-icon"
                    target="_blank"
                  >
                    <Paragraph>
                      <img src={documentIconSrc} alt="Find documents" />
                      <span>Find the documents you need with Docutrieve</span>
                    </Paragraph>
                  </Link>
                </div>
                <div className="contact-split contact-image">
                  <img
                    src={iAmARealtorImage.file.url}
                    alt={iAmARealtorImage.description}
                  />
                </div>
              </div>
            </div>

            <div className="tile">
              <div className="">
                <div className="flex-wrapper">
                  <div className="contact-split">
                    <Paragraph>{`I want to learn more`}</Paragraph>
                    <Heading level={3} style={{ marginTop: 0 }}>
                      About Hawaiiana
                    </Heading>
                    <Link
                      href="/about"
                      className="link-with-icon"
                      target="_blank"
                    >
                      <Paragraph>
                        <img src={informationIconSrc} />
                        <span>Learn more about us</span>
                      </Paragraph>
                    </Link>
                  </div>
                  <div className="contact-split contact-image">
                    <img
                      src={learnMoreAboutHawaiianaImage.file.url}
                      alt={learnMoreAboutHawaiianaImage.description}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-white-gradient-bottom" />
      </div>

      <PageSection className="home-section">
        <PageContent className="home-section">
          <Heading level={1} style={{ margin: 0 }}>
            Need to Contact Us? <br />
          </Heading>
          <div className="flex-wrapper">
            <div className="home-contact">
              <Heading level={3}>Questions about your bill?</Heading>
              <Paragraph>
                Payment, billing, and fee questions—
                <br />
                <Link href={`tel:${paymentPhoneNumber}`}>
                  {paymentPhoneNumber}
                </Link>
                <br />
                <Link href={`mailto:${paymentEmail}`}>{paymentEmail}</Link>
              </Paragraph>
            </div>
            <div className="home-contact">
              <Heading level={3}>
                {`Contact your Hawaiiana Management Executive or On-Site Manager
          directly`}
              </Heading>
              <Button href="#find-community">Find Your Community Below</Button>
            </div>
            <div className="home-contact">
              <Heading level={3}>Would you like to request a proposal?</Heading>
              <Paragraph>
                {`Contact us directly at—`}
                <br />
                <Link href={`tel:${inquiryPhoneNumber}`}>
                  {inquiryPhoneNumber}
                </Link>
                <br />
                <Button href="/request-a-proposal">Request a Proposal</Button>
              </Paragraph>
            </div>
          </div>
        </PageContent>
      </PageSection>

      <div
        id="find-community"
        style={{
          backgroundImage: `url(${
            findInformationAboutYourCommunityImage.file.url
          })`
        }}
        className="home-find-community home-section"
      >
        <Tile>
          <Heading level={3}>Find information about your community</Heading>
          <Paragraph>
            {`Get up-to-date information about your community including contact details, management information and eStatement availability`}
          </Paragraph>
          <form
            onSubmit={event => {
              event.preventDefault();
              window.location = `/find-a-community/?query=${
                event.target.query.value
              }`;
            }}
          >
            <Input
              placeholder="Search by address or building name"
              name="query"
            />
            <Button type="submit">Find My Community</Button>
          </form>
        </Tile>
      </div>
      <PageSection
        className="section-light-gradient"
        style={{ marginBottom: 0 }}
      >
        <PageContent className="split--hero-right home-stand-out-section">
          {aboutHawaiianaSection.map((content, index) => {
            return (
              <div key={`about-us-${index}`}>
                <Heading level={2}>{content.node.title}</Heading>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      content.node
                        .childContentfulGeneralEditableFieldContentRichTextNode
                        .childContentfulRichText.html
                  }}
                  key={`about-us-header-${index}`}
                />
                <Button href="/about">Learn More</Button>
              </div>
            );
          })}

          <div>
            <img
              className="find-your-community--sidebar-img"
              src={aboutHawaiianaImage.file.url}
              alt={aboutHawaiianaImage.description}
            />
          </div>
        </PageContent>
      </PageSection>
    </Page>
  );
};

export default Index;
